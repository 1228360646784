import * as React from "react";
import {useObjectHistoryQuery, useObjectHistoryUserQuery} from "../../Queries";
import dayjs from "dayjs";
import {
   Timeline,
   TimelineConnector,
   TimelineContent,
   TimelineDot,
   TimelineItem,
   TimelineOppositeContent,
   TimelineSeparator
} from '@mui/lab';
import {Box, Divider, Typography} from '@mui/material';
import {BodyText, TitleText} from "../../layout/Typography";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../atoms/CurrentUser";

type ObjectHistoryProps = {
   refId: string
}

export const ObjectHistory = ({refId}: ObjectHistoryProps) => {
   if(!refId) return null;
   return <ObjectHistoryInner refId={refId}/>
}

const ObjectHistoryInner = ({refId}: ObjectHistoryProps) => {
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const {data} = useObjectHistoryQuery({
      variables: {
         ref: refId
      }
   });

   if (!isAdministrator) {
      return null;
   }

   const patches = data?.PatchPagination?.items || [];

   return (
      <Box sx={{width: '100%', marginTop: '30px'}}>
         <Divider sx={{marginBottom: '20px'}}/>
         <TitleText type={"h1"}>History</TitleText>
         <Timeline position="right">
            {patches.map((patch: any) => (
               <TimelineItem key={patch._id}>
                  {/* Date on the left side of the dot */}
                  <TimelineOppositeContent
                     sx={{flex: 0.2, paddingRight: "16px", textAlign: "right"}}
                  >
                     <Typography variant="body2" color="textSecondary">
                        {dayjs(patch.date).format("D MMM YYYY, HH:mm")}
                     </Typography>
                  </TimelineOppositeContent>

                  {/* Timeline dot and connector */}
                  <TimelineSeparator>
                     <TimelineDot/>
                     <TimelineConnector/>
                  </TimelineSeparator>

                  {/* Main content on the right side of the dot */}
                  <TimelineContent sx={{flex: 1, paddingLeft: "16px"}}>
                     <Box>
                        <BodyText type={"body1"} sx={{fontWeight: 'bold'}}>
                           {patch.updatedBy && <HistoryUser userId={patch.updatedBy.ref}/>}
                        </BodyText>
                        <ul>
                           {patch.ops.map((op: any, index: number) => (
                              <li key={index}>
                                 <Typography variant="body2">
                                    <PatchDescription {...op}/>
                                 </Typography>
                              </li>
                           ))}
                        </ul>
                     </Box>
                  </TimelineContent>
               </TimelineItem>
            ))}
         </Timeline>
      </Box>
   );
};

// Helper function to describe patch operations in human-readable format
type PatchDescriptionProps = {
   value?: any | null
   op?: string | null
   path?: string | null
}

const PatchDescription = ({value, path, op}: PatchDescriptionProps) => {
   const formattedPath = Array.isArray(path) ? path.join('.') : path; // Convert path array to dot notation string

   const renderPath = () => (
      <Typography
         component="span"
         sx={{
            color: 'red',
            backgroundColor: 'lightyellow',
            padding: '2px 4px',
            borderRadius: '4px',
            fontFamily: 'monospace'
         }}
      >
         {formattedPath}
      </Typography>
   );

   switch (op) {
      case 'add':
      case 'replace':
         return (
            <>
               <Typography variant="body2">
                  Updated {renderPath()} to:
               </Typography>
               <Box sx={{paddingLeft: 2}}>
                  <Box sx={{fontFamily: 'Courier'}}>
                     {JSON.stringify(value, null, 2)}
                  </Box>
               </Box>
            </>
         );
      case 'remove':
         return (
            <Typography variant="body2">
               Removed {renderPath()}
            </Typography>
         );
      default:
         return (
            <Typography variant="body2">
               Unknown operation {op}
            </Typography>
         );
   }
};


export const HistoryUser = ({userId}: { userId: string }) => {
   const {data} = useObjectHistoryUserQuery({
      variables: {
         id: userId
      }
   });
   return <div>{data?.User?.name || data?.User?.email || 'API / Brand'}</div>
}